import { Pipe, PipeTransform } from '@angular/core';
const ELASTIC_RESOURCE_TYPE = {
  '86405': 'article',
  '86406': 'article',
  '86407': 'podcast',
  '86409': 'template',
  '86411': 'webinar',
};
const ELASTIC_RESOURCE_LINK = {
  '86405': 'Read Article',
  '86406': 'Read Article',
  '86407': 'Listen to Podcast',
  '86409': 'View Template',
  '86411': 'Watch Webinar',
};

@Pipe({
  name: 'elasticResourceType',
  standalone: true,
})
export class ElasticResourceTypePipe implements PipeTransform {
  transform(value: string, transformType: string): string {
    if (transformType === 'type') {
      return ELASTIC_RESOURCE_TYPE[value as keyof typeof ELASTIC_RESOURCE_TYPE] || '';
    } else if (transformType === 'link') {
      return ELASTIC_RESOURCE_LINK[value as keyof typeof ELASTIC_RESOURCE_LINK] || 'Preview';
    }
    return '';
  }
}
