import { Pipe, PipeTransform } from '@angular/core';
import { BundleTypeEnum, MyProgramModel, ProgramDashboardModel } from '@lms/shared/models';
import { TrademarkPipe } from './trademark.pipe';

@Pipe({
  name: 'programFullTitle',
  standalone: true,
})
export class ProgramFullTitlePipe implements PipeTransform {
  constructor(protected trademarkPipe: TrademarkPipe) {}

  public transform(value: null | MyProgramModel | ProgramDashboardModel, displayOnlyProgramType: boolean = false): string {
    if (!value) {
      return '';
    }

    let programType = '';

    switch (value?.type) {
      case BundleTypeEnum.PROGRAM_TYPE:
        programType = 'Certification';
        break;
      case BundleTypeEnum.LEARNING_PATH_TYPE:
        programType = 'Learning Path';
        break;
      case BundleTypeEnum.SPECIALIZATION_TYPE:
        programType = 'Specialization';
        break;
      default:
        break;
    }

    if (displayOnlyProgramType) {
      return programType;
    }

    if (value.shortName) {
      return value.shortName + this.trademarkPipe.transform(value.slug) + ' ' + programType;
    }

    return `${value.name} ${programType}`;
  }
}
