import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
@Component({
  selector: 'app-sortable-col',
  templateUrl: './sortable-col.component.html',
  styleUrls: ['./sortable-col.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SortableColComponent {
  @Input()
  field: string = '';

  @Input()
  currentSort: string = '';

  @Input() withoutQuery: boolean = false;
  @Output() sortChange = new EventEmitter<string>();

  changed(value: string): void {
    if (!this.withoutQuery) return;
    this.sortChange.emit(value);
  }
}
